import { useNavigate, useParams } from 'react-router-dom';

import { useDecision } from '@optimizely/react-sdk';
import { TypeToast, usePreferredLanguageV2, useToast } from 'admin-portal-shared-services';

import { FEATURE_TOGGLE_VISIT_EFFECTIVE_ROUTE } from '@/consts/FeatureToggles';
import { PATH_BASE, PATH_HOME } from '@/consts/route';
import { Locales } from '@/enums/locales';
import { VisitDetailsSegmentProps } from '@/types/AnalyticsTypes';

import { useSegmentVisitDetails } from '../components/NextVisit/hooks/useSegmentVisitDetails/useSegmentVisitDetails';
import { visits } from '../consts/Visit.mock';
import { VisitType } from '../types/VisitDetails.types';
import { useTranslation } from './useTranslation/useTranslation';

export const useVisitDetails = () => {
  const navigate = useNavigate();
  const { dispatchVisitDetailsEvent } = useSegmentVisitDetails();
  const toastService = useToast();
  const { cancelVisitToastSuccess } = useTranslation();
  const { id } = useParams();

  const [{ enabled: isFeatureVisitEffectiveRouteEnabled }] = useDecision(
    FEATURE_TOGGLE_VISIT_EFFECTIVE_ROUTE,
  );

  const { preferredLanguage } = usePreferredLanguageV2() as { preferredLanguage: Locales };

  const visitInfo: VisitType = visits.find((visit) => visit.id === id) || {
    id: '',
    date: '',
    status: '',
    suggestedTime: '',
    minimumTime: '',
    maximumTime: '',
    poc: {
      id: '',
      name: '',
      email: '',
      latitude: '',
      longitude: '',
    },
    bdr: {
      id: '',
      name: '',
    },
    nextVisit: {
      date: '',
      status: '',
    },
  };

  const handleClickGoBackButton = () => {
    navigate(`${PATH_HOME}${PATH_BASE}`);
    dispatchVisitDetailsEvent(
      VisitDetailsSegmentProps.BackVisitList,
      VisitDetailsSegmentProps.VisitDetails,
    );
  };

  const handleCancelVisit = (visitId: string | undefined) => {
    // eslint-disable-next-line no-console
    console.log('Visit Canceled', visitId);

    toastService.notify({
      message: cancelVisitToastSuccess,
      type: TypeToast.SUCCESS,
    });
  };

  return {
    isFeatureVisitEffectiveRouteEnabled,
    handleClickGoBackButton,
    preferredLanguage,
    visitInfo,
    handleCancelVisit,
  };
};
