import MessageMap from '../i18n.d';

export const es: MessageMap = {
  test: {
    translate: 'Traducir',
    missing: 'Solo disponible la opción en inglés',
  },
  Pages: {
    Visits: {
      Title: {
        PAGE_VISITS: 'Visitas',
      },
    },
  },
  Components: {
    Overview: {
      Title: {
        OVERVIEW: 'Descripción general',
        CANCELLED_VISITS: '% Visitas canceladas',
        BDRS_WITHOUT_VISITS: '% de BDRs sin visitas',
        TOTAL_OF_VISITS: 'Total de visitas',
      },
      Label: {
        CANCELLED_VISITS_TOTAL: ' de [total] Visitas',
        BDRS_WITHOUT_VISITS_TOTAL: 'de [total] BDRs',
        TOTAL_OF_VISITS_COUNT: 'Total',
        LAST_UPDATE: 'Última actualización hace [lastUpdate] horas.',
        FILTER_DESCRIPTION: 'Seleccione el rango de datos que desea que se muestre.',
      },
      Button: {
        REFRESH: 'Actualizar',
      },
      Error: {
        REFRESH_ERROR: 'No pudimos actualizar',
      },
      Filter: {
        Periods: {
          TODAY: 'Hoy',
          YESTERDAY: 'Ayer',
          TOMORROW: 'Mañana',
          OTHER: 'Otro',
        },
      },
      Graph: {
        TITLE: 'Por estado',
        SCHEDULED: 'Programada',
        IN_PROGRESS: 'En curso',
        COMPLETED: 'Terminada',
        JUSTIFIED: 'Justificada',
        CANCELED: 'Cancelada',
      },
    },
    VisitTableCard: {
      SearchBar: {
        ACCOUNT_ID: 'ID del PDV',
        BDR_ID: 'ID del BDR',
        BDR_NAME: 'Nombre de BDR',
        SEARCH_BY: 'Buscar por',
      },
      Buttons: {
        SEARCH_BUTTON: 'Buscar',
        FILTERS_BUTTON: 'Filtros',
        APPLY_BUTTON: 'Aplicar',
        CANCEL_BUTTON: 'Cancelar',
        CLEAR_ALL_BUTTON: 'Borrar todo',
      },
      Title: {
        CARD_VISITS: 'Visitas',
      },
      Error: {
        ACCOUNT_ID_ERROR: 'ID del PDV [id] no existe.',
        BDR_ID_ERROR: 'BDR [id] no existe.',
        BDR_NAME_ERROR: 'BDR [name] no existe.',
      },
      StatusVisits: {
        STATUS: 'Estado',
        SCHEDULED: 'Programada',
        IN_PROGRESS: 'Em curso',
        COMPLETED: 'Terminada',
        JUSTIFIED: 'Justificada',
        CANCELED: 'Cancelada',
      },
      TableVisits: {
        SHOWING_RESULTS: 'Mostrando [results] visitas',
        BDR_ID: 'ID del BDR',
        BDR_NAME: 'Nombre de BDR',
        BDR_ACCOUNT_ID: 'ID del PDV',
        VISIT_DATE: 'Fecha de visita',
        MIN_TIME: 'Tiempo Min.',
        MAX_TIME: 'Tiempo Máx.',
        MINUTES: 'Minutos',
        QUANTITY_INDICATION: 'de',
      },
      Filter: {
        FILTERED_BY: 'Filtrado por',
      },
    },
    ModalBDRsWithoutVisits: {
      Title: {
        BDRS_WITHOUT_VISITS: 'BDRs sin visitas',
      },
      Label: {
        SHOWING_RESULTS: 'Mostrando [results] resultados',
        BDR_ID: 'ID del BDR',
        BDR_NAME: 'Nombre de BDR',
        DATE_WITHOUT_VISITS: 'Fecha sin visita',
        SEARCH_BY: 'Buscar por ID del BDR',
        PAGINATION_INDICATION: 'de',
        RANGE_INDICATION: 'al',
      },
    },
    VisitDetails: {
      Title: {
        VISIT_DETAILS: 'Detalles de la visita',
        POC_INFORMATION: 'Información de POC',
        NEXT_VISIT: 'Próxima visita',
        BDR_INFORMATION: 'Información de BDR',
        VISIT_INFORMATION: 'Información de la visita',
        CANCEL_VISIT: '¿Cancelar visita?',
      },
      Label: {
        VISIT_DATE: 'Fecha de visita',
        VISIT_STATUS: 'Estado de la visita',
        SUGGESTED_TIME: 'Tiempo sugerido',
        MINIMUM_TIME: 'Tiempo mínimo',
        MAXIMUM_TIME: 'Tiempo máximo',
        NO_NEXT_VISIT: 'No hay una próxima visita programada para este POC.',

        POC_NAME: 'Nombre del POC',
        POC_ID: 'Identificación de POC',
        POC_LATITUDE: 'Latitud',
        POC_LONGITUDE: 'Longitud',
        NO_POC_INFORMATION: 'No hay información de POC para esta visita.',

        BDR_NAME: 'Nombre de BDR',
        BDR_ID: 'Identificación de BDR',
        NO_BDR_INFORMATION: 'No hay información de BDR para este POC.',

        SHOW_VISIT_DETAILS: 'Mostrar detalles de la visita',
        MINUTES: '[min] minutos',
        NO_DATA_AVAILABLE: 'No hay datos disponibles',
        CANCELED_DUE_TO: 'Esta visita fue cancelada por el sistema debido a [error].',

        CANCEL_VISIT_DESCRIPTION:
          'Esta acción se puede deshacer siempre que la fecha permanezca en el futuro.',
      },
      Button: {
        CANCEL_MODAL: 'Cancelar',
        GO_BACK: 'No, volver',
        CANCEL_VISIT: 'Sí, cancelar',
      },
      Toast: {
        SUCCESS: 'Visita cancelada',
      },
    },
    FilterModalCalendar: {
      Button: {
        SAVE: 'Ahorrar',
        RESET: 'Reiniciar',
      },
    },
    BadgeStatus: {
      Label: {
        SCHEDULED: 'Programada',
        IN_PROGRESS: 'Em curso',
        COMPLETED: 'Terminada',
        JUSTIFIED: 'Justificada',
        CANCELED: 'Cancelada',
      },
    },
  },
};
