import { Alert } from '@hexa-ui/components';
import styled from 'styled-components';

export const WrapperVisitDetailsCard = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: ${({ theme }) => theme.measure.space['space-4']};
`;

export const VisitDetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.measure.space['space-4']};
  margin-bottom: ${({ theme }) => theme.measure.space['space-9']};
  justify-content: space-between;
`;

export const AlertError = styled(Alert)`
  margin: ${({ theme }) => theme.measure.space['space-4']} 0;
  width: 100%;
`;

export const WrapperHeading = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.measure.space['space-4']};
`;
